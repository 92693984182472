<template>
  <head-panel>
    <template v-slot:body>
      Dashboard
      <counter @onChange="changeCounter" class="counter"/>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="ps-4 pe-4">

    <div class="stats-wrapper mb-3" >

      <div class="row">
        <div class="col-auto">
          <date-range @onChange="changeDateRange" :date-range="dateRange"/>
        </div>
      </div>


      <div style="height: 400px;">
        <v-chart class="chart" :option="chart"/>
      </div>
    </div>

    <top-search-table :counter="counter"/>
  </div>

  </div>

</template>

<script>
import Http from "../../lib/Http";
import Counter from "../../components/Counter";
import DateRange from "../../components/DateRange";
import TopSearchTable from "./TopSearchTable";
import moment from 'moment'
import HeadPanel from "../../components/HeadPanel";

export default {
  name: "Index",
  components: {
    Counter,
    DateRange,
    TopSearchTable,
    HeadPanel
  },
  data() {
    return {
      counter: null,
      dateRange: [moment().subtract(1, 'month').toDate(), moment().toDate()],
      chart: null
    }
  },
  methods: {
    changeCounter: function (counter) {
      this.counter = counter;
      this.fetchSearch();
    },
    changeDateRange: function (range) {
      this.dateRange = range;
      this.fetchSearch();
    },
    fetchSearch: function () {
      const from = moment(this.dateRange[0]).format("YYYY-MM-DD");
      const to = moment(this.dateRange[1]).format("YYYY-MM-DD");
      Http.get('/v1/analytics/keyword/chart?counter=' + this.counter + '&from=' + from + '&to=' + to)
          .then(res => {
            this.chart = this._chartData(res.content);
          });
    },
    _chartData: function (data) {

      const xAxisData = [];
      const seriesLineData = [];
      for (let item of data) {
        xAxisData.push(item.date);
        seriesLineData.push(item.count);
      }

      return {
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'shadow'
          // }
        },
        legend: {},
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          // width: '100%',
          containLabel: true
        },
        xAxis: [
          {
            // interval: 100,
            // min: 5,
            // max: 10,
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'right'
            // splitLine: {
            //   show: false
            // }
          }
        ],
        series: [
          {
            name: 'Searches',
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              color: '#248afd',
            },
            itemStyle: {
              color: '#248afd',//'#f5803e',
            },
            areaStyle: {
              opacity: 0.1,
              color: '#248afd'
            },
            data: seriesLineData
          },
        ]
      };
    }
  }
};
</script>

<style scoped>
x-vue-echarts{
  margin-bottom: 40px;
}
</style>
