<template>
  <div class="head-title">Synonym
    <router-link :to="'/vocabulary/'+vid+'/synonym'">
      <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button"><i class='bx bx-arrow-back icon'></i>
        Back
      </button>
    </router-link>


    <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="synonym">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
           viewBox="0 0 16 16">
        <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fill-rule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
      </svg>
      Remove synonym
    </a>

  </div>

  <div class="wrapper">

    <div class="ps-4 pe-4">


      <div class="card">
        <div class="card-body">

          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="error of errors" :key="error">{{ error.message }}</div>
          </div>
          <form @submit.prevent="submit">


            <div class="mb-3">
              <label class="form-label">Name</label>
              <input type="text"
                     class="form-control"
                     :class="{ 'is-invalid' : v$.form.name.$error}"
                     v-model="form.name">
              <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>


            <div class="mb-3">
              <label class="form-label">Synonyms</label>



              <Multiselect v-model="form.synonyms"
                           class="tagged"
                           :options="synonymOptions"
                           mode="tags"
                           no-results-text=""
                           no-options-text=""
                           :close-on-select="false"
                           :searchable="true"
                           :create-option="true"
                           :class="{ 'is-invalid' : v$.form.synonyms.$error}"
              >
              </Multiselect>
              <div id="emailHelp" class="form-text">Press Enter to add term</div>

              <div class="invalid-feedback" v-for="error of v$.form.synonyms.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Save</button>
          </form>


        </div>
      </div>


    </div>

  </div>

  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import {required, minLength, maxLength, helpers} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import Http from "../../lib/Http";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Multiselect from "@vueform/multiselect";

export default {
  name: "SynonymUpdate",
  components: {
    ConfirmDialogue, Multiselect
  },
  created() {
    if (this.$route.params.id)
      this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {required, minLength: minLength(3), maxLength: maxLength(255)},
        synonyms: {
          $each: helpers.forEach({
            required,
            minLength: minLength(3),
            maxLength: maxLength(500),
            validateSynonym: helpers.withMessage('Invalid charset', (v) => v.match("^[\\w0-9А-ЯҐЄІЇа-яґєії,]+$"))
          })
        },
      }
    }
  },
  data() {
    return {
      vid: this.$route.params.vid,
      synonym: null,
      synonymOptions: [],
      form: {
        name: null,
        synonyms: null,
      },
      errors: [],
    }
  },
  methods: {
    fetch: function () {
      Http.get( '/v1/synonym/' + this.$route.params.id)
          .then((res) => {
            this.vid = res.content.vocabulary.uuid;
            this.synonym = res.content;
            this.form = res.content;
            this.synonymOptions = res.content.synonyms.map(e => {
              return {value: e, label: e}
            });
          });
    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        name: this.form.name,
        synonyms: this.form.synonyms,
      };

      let request = !this.synonym ?
          Http.post( '/v1/synonym/' + this.vid, data) :
          Http.put( '/v1/synonym/' + this.synonym.uuid, data);

      request.then(res => {
        this.$router.replace("/vocabulary/" + res.content.vocabulary.uuid + "/synonym");
      }).catch((e) => {
        this.$refs.alert.danger(e.content[0].message);
      });
    },
    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Removing',
        message: 'Remove synonym and all it data?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .del( "/v1/synonym/" + this.synonym.uuid)
              .then(() => {
                this.$router.replace("/vocabulary/" + this.synonym.vocabulary.uuid + "/synonym");
              });
      })
    },
  }
}
</script>

<style>



</style>
