<template>
  <head-panel>
    <template v-slot:body v-if="vocabulary" >

      <div class="breadcrumb">

      <div class="name">Synonyms</div>
      <div class="divider"></div>
      <div>
        <router-link :to="'/vocabulary/'"  >
            Vocabularies
        </router-link>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      <span class="name">{{ vocabulary.name }}</span>
      </div>
      <div class="divider"></div>
      <router-link :to="'/vocabulary/'+vocabulary.uuid+'/synonym/create'">
        <button class="btn btn-primary btn-sm btn-create-top" role="button">Add</button>
      </router-link>
      </div>


    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter" v-if="vocabulary">
      <div class="item">
        <SearchFilter
            label="Search..."
            name="search"
            :path="'/vocabulary/'+vocabulary.uuid+'/synonym'"
            v-model="filter.search"
        />
      </div>
      <div class="item ms-3">

      </div>
    </div>



      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Synonyms</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.name }}</td>
          <td>{{ item.synonyms.slice(0, 3).join(", ") }} ...</td>
          <td class="actions">
            <router-link :to="'/synonym/'+item.uuid">Ред.</router-link>
          </td>
        </tr>
        </tbody>
      </table>


    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>


  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination.vue";
import SearchFilter from "../../components/SearchFilter";

export default {
  name: "Schedule",
  components: {
    HeadPanel, ConfirmDialogue, Pagination, SearchFilter
  },
  created() {
    this.fetch(this.$route.params.vid);
    this.fetchVocabulary(this.$route.params.vid);
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch(this.$route.params.vid);
    },
  },
  data() {
    return {
      filter: {
        search: null
      },
      vocabulary: null,
      items: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function (id) {

      let query = {
        vocabulary: id
      };

      if(this.$route.query.search)
        query.search = this.$route.query.search;

      Http.get( '/v1/synonym?' + (new URLSearchParams(query)).toString())
          .then(res => this.items = res.content);
    },
    fetchVocabulary: function (id) {
      Http.get( '/v1/vocabulary/' + id)
          .then(res => this.vocabulary = res.content);
    }
  },
}
</script>

<style scoped>

</style>
