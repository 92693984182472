<template>
  <div class="head-title">Counter
    <router-link to="/counters/">
      <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button"><i class='bx bx-arrow-back icon'></i>
        Back
      </button>
    </router-link>
  </div>

  <div class="wrapper">

    <Alert ref="alert"/>

    <div class="ps-4 pe-4">


      <div class="row">
        <div class="col-xxl-4 col-xl-7 col-lg-10 col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="alert alert-danger" v-if="errors.length">
                <div v-for="error of errors" :key="error">{{ error.message }}</div>
              </div>
              <form @submit.prevent="submit">


                <div class="mb-3">
                  <label class="form-label">Project name</label>
                  <input type="text"
                         class="form-control"
                         :class="{ 'is-invalid' : v$.form.name.$error}"
                         v-model="form.name">
                  <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>


                <div class="mb-3">
                  <label class="form-label">Site</label>
                  <input type="text"
                         class="form-control"
                         :class="{ 'is-invalid' : v$.form.site.$error}"
                         v-model="form.site">
                  <div class="form-text">Root domain without schema. Example: google.com</div>
                  <div class="invalid-feedback" v-for="error of v$.form.site.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>


                <div class="mb-3">
                  <label class="form-label">External search URL</label>
                  <input type="text"
                         class="form-control"
                         :class="{ 'is-invalid' : v$.form.externalSearchUrl.$error}"
                         v-model="form.externalSearchUrl">
                  <div class="form-text">Search URL on your site</div>
                  <div class="invalid-feedback" v-for="error of v$.form.externalSearchUrl.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Language</label>
                  <select :disabled="this.$route.params.id !== undefined" class="form-select"
                          :class="{ 'is-invalid' : v$.form.lang.$error}"
                          v-model="form.lang">
                    <option value="">-- Select --</option>
                    <option value="ua">UA</option>
                  </select>
                  <div class="form-text">Index main language</div>
                  <div class="invalid-feedback" v-for="error of v$.form.lang.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Index URL</label>
                  <input type="text"
                         class="form-control"
                         :class="{ 'is-invalid' : v$.form.indexFileUrl.$error}"
                         v-model="form.indexFileUrl">
                  <div class="form-text">Exist index map url</div>
                  <div class="invalid-feedback" v-for="error of v$.form.indexFileUrl.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>


                <div class="mb-3 vc">
                  <label class="form-label">Vocabularies</label>
                  <Multiselect v-model="form.vocabularies" :options="vocabularies" mode="tags" no-results-text=""
                               :close-on-select="false"></Multiselect>

                </div>

                <button type="submit" class="btn btn-primary">Save</button>
              </form>
            </div>
          </div>


        </div>
      </div>

    </div>

  </div>


</template>

<script>

import {required, minLength, url, helpers} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import Http from "../../lib/Http";
import Alert from "../../components/Alert";
import Multiselect from '@vueform/multiselect'

export default {
  name: "CounterUpdate",
  components: {
    Alert, Multiselect
  },
  created() {
    if (!this.$route.params.id)
      return;

    this.fetch();
    this.fetchVocabularies();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {required, minLength: minLength(4)},
        site: {
          required,
          validateMapIndexUrl: helpers.withMessage('Invalid site domain', (v) => v.match("^[-a-zA-Z0-9@_:.]+$"))
        },
        externalSearchUrl: {required, url},
        indexFileUrl: {
          required,
          validateMapIndexUrl: helpers.withMessage('Invalid index map url', (v) => v.match("^(https?|ftp|file)://[-a-zA-Z0-9@_:.]+[-a-zA-Z0-9+/&@?=_.]+$"))
        },
        lang: {required}
      }
    }
  },
  data() {
    return {
      errors: [],
      vocabularies: [],
      options: [
        {value: 'batman', label: 'Batman'},
        {value: 'robin', label: 'Robin'},
        {value: 'joker', label: 'Joker'},
      ],
      form: {
        name: null,
        site: null,
        search: null,
        index: null,
        lang: "",
        vocabularies: null
      },
    }
  },
  methods: {
    fetch: function () {
      Http.get( '/v1/counter/' + this.$route.params.id)
          .then((res) => {
            this.form = res.content;
            this.form.vocabularies = res.content.vocabularies ? res.content.vocabularies.map(e => {
              return e.uuid;
            }) : [];
          });
    },
    fetchVocabularies: function () {
      Http.get( '/v1/vocabulary')
          .then((res) => {
            this.vocabularies = res.content.map(e => {
              return {value: e.uuid, label: e.name};
            });
          });

    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        name: this.form.name,
        lang: this.form.lang,
        site: this.form.site,
        indexFileUrl: this.form.indexFileUrl,
        externalSearchUrl: this.form.externalSearchUrl,
        vocabularies: this.form.vocabularies
      };

      console.log(data);


      let request = this.$route.params.id === undefined ?
          Http.post( '/v1/counter', data) :
          Http.put( '/v1/counter/' + this.$route.params.id, data);

      request.then(() => {
        this.$router.push('/counters')
      }).catch((e) => {
        this.$refs.alert.danger(e.content[0].message);
      });
    }
  }
}
</script>

<style>
.vc .multiselect-tag-remove-icon {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  border-radius: 50%;
  background-color: red;
}


.vc .multiselect-tags {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}

.vc .multiselect-tag {
  float: left;
  margin-right: 10px;
  border-radius: 6px;
  padding: 2px 8px;
  background-color: #4587f5;
  cursor: pointer;
  color: #fff;
}

.vc .multiselect-tag-remove {
  display: inline-block;
}

.vc .multiselect-options {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}


.vc .multiselect-option {
  float: left;
  margin-right: 10px;
  border-radius: 6px;
  padding: 2px 8px;
  background-color: #eae8e8;
  cursor: pointer;
}

</style>
