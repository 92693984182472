<template>

  <div class="stats-wrapper">
    <el-table
        :data="tableData"
        :default-sort="{ prop: 'count', order: 'descending' }"
        style="width: 100%"
    >
      <el-table-column prop="keyword" label="Keyword" sortable/>
      <el-table-column prop="count" label="Count" sortable/>
      <el-table-column prop="growing" label="Growing" :formatter="(v)=> v.growing+'%'">

        <template #header>
          <el-input v-model="search" size="small" placeholder="Type to search"/>
        </template>

      </el-table-column>
    </el-table>

    <div class="table-pagination">
      <div class="counter">
        {{this.page * this.perPage + 1}}-{{ this.total > (this.page + 1) * this.perPage ? this.total : (this.page + 1) * this.perPage }} of {{ this.total }}
      </div>
      <div class="pages">
        <button class="btn btn-secondary btn-sm" @click="prev">Previous</button>
        <button class="btn btn-primary btn-sm" @click="next">Next</button>
      </div>
    </div>

  </div>

</template>

<script>

import Http from "../../lib/Http";
import moment from "moment";

export default {
  name: "Table",
  props: {
    counter: String
  },
  watch: {
    counter(counter) {
      if (counter)
        this.fetchKeywords(counter)
    },
    search() {
      this.page = 0;
      this.tableData = this.filter(this.keywords);
    }
  },
  data() {
    return {
      page: 0,
      perPage: 10,
      total: 0,
      search: null,
      keywords: [],
      tableData: [],
    };
  },
  methods: {
    fetchKeywords: function (counter) {
      let currentFrom = moment().subtract(1, 'M').format("YYYY-MM-DD");
      let currentTo = moment().format("YYYY-MM-DD");
      let prevFrom = moment().subtract(2, 'M').format("YYYY-MM-DD");
      let prevTo = moment().subtract(1, 'M').format("YYYY-MM-DD");

      let currentMonthResult = [];
      Http.get('/v1/analytics/keyword?counter=' + counter + '&from=' + currentFrom + '&to=' + currentTo)
          .then(res => currentMonthResult = res)
          .then(() => Http.get('/v1/analytics/keyword?counter=' + counter + '&from=' + prevFrom + '&to=' + prevTo))
          .then(res => {
            this.keywords = this.merge([currentMonthResult.content, res.content]);
            this.tableData = this.filter(this.keywords);
          });
    },

    next() {
      if ((this.page + 1) * this.perPage >= this.keywords.length)
        return;
      this.page++;
      this.tableData = this.filter(this.keywords);
    },

    prev() {
      if (this.page < 1)
        return;
      this.page--;
      this.tableData = this.filter(this.keywords);
    },

    filter(items) {
      let result = this.search ? items.filter(item => item.keyword.search(this.search) !== -1) : items;
      this.total = result.length
      return result.slice(this.page * this.perPage, (this.page + 1) * this.perPage);
    },

    merge: function (data) {

      let result = [];
      let indexed = {};

      for (let item of data[1])
        indexed[item.keyword] = item;

      for (let item of data[0]) {
        const percent = indexed[item.keyword] !== undefined ? this.percent(item.count, indexed[item.keyword].count) : 0;
        result.push({count: item.count, keyword: item.keyword, growing: percent.toPrecision(1)});
      }
      return result;
    },

    percent: function (newVal, oldVal) {
      return newVal > oldVal ?
          (newVal - oldVal) / oldVal :
          ((oldVal - newVal) / oldVal) * -1;
    }
  }
}
</script>

<style scoped>


</style>
