<template>
  <head-panel>
    <template v-slot:body>
      Vocabularies

      <router-link to="/vocabulary/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">Add new vocabulary
        </button>
      </router-link>

      <a @click="exportSynonym" class="btn btn-primary btn-sm btn-remove float-end" >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Export
      </a>

    </template>
  </head-panel>

  <div class="wrapper">


    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Terms</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in vocabularies" :key="item.id">
        <td>{{ item.name }}</td>
        <td>{{ item.counts }}</td>
        <td class="actions">
          <router-link :to="'/vocabulary/'+item.uuid">Edit</router-link>
          <router-link :to="'/vocabulary/'+item.uuid+'/synonym'">List terms</router-link>
        </td>
      </tr>
      </tbody>
    </table>


    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>


  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination.vue";


export default {
  name: "Schedule",
  components: {
    HeadPanel, ConfirmDialogue, Pagination,
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      counter: null,
      vocabularies: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      this.counter = this.$cookies.get('counter');
      Http.get( '/v1/vocabulary')
          .then(res => this.vocabularies = res.content);
    },
    // run: function () {
    //
    //   this.$refs.confirmDialogue.show({
    //     title: 'Schedule index',
    //     message: 'Do you really want Schedule import ?',
    //     okButton: 'Yes',
    //     cancelButton: 'No',
    //   }).then((ok) => {
    //     if (ok)
    //       Http.post('/syn/v1/schedule/run?counter=' + this.counter)
    //           .then(() => this.fetch());
    //   })
    // },
    exportSynonym: function (){
      this.$refs.confirmDialogue.show({
        title: 'Schedule index',
        message: 'Do you really want apply synonyms ?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http.post('/v1/synonym/refresh/' + this.counter)
              .then(() => this.fetch());
      })
    },
  },
}
</script>

<style scoped>

</style>
